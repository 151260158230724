import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip'
import moment from 'moment';
import { concat, sumBy, map } from 'lodash';
import { Link as LiveLink } from 'react-router-dom';
import ReadonlyLink from './ReadonlyLink';
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';
import { avgFooter, convertSumFooter, textFooter, spaceFooter } from '../../common/Datagrid/Footers';
const CellHeaderTable = cellHeaderHOC(ReactTable);
import { getTime } from '../../../helpers/DateFormatter';
import {round, splitByThousands, convertToThousands } from '../../../helpers/NumberFormatter';
import copyToClipboard from '../../../helpers/copyToClipboard';
import CopyToClipboard from '../../common/CopyToClipboard';
import VendorLogo from '../../common/VendorLogo';
import PercentageValue from '../../common/PercentageValue';
import AbsoluteValue from '../../common/AbsoluteValue';
import Tooltip from '../../common/Tooltip';
import config from '../../../config/config';

const SelectTable = selectTableHOC(ReactTable);



var copySvg = require('!svg-inline-loader!../../../../public/img/copy-icon.svg');

function getName(item, entity) {
    if(entity == 'playlists')
        return <Link to={`/${entity}/${item.id}/details`}>{item.name} {item.code && <span className="newline">{item.code}</span>} </Link>;
    else
        return <Link to={`/${entity}/${item.id}`}>{item.name}</Link>;
}

function getCopyValue(item) {
    return <span className="isrc-holder with-copy"><CopyToClipboard value={item.code}>{item.code}</CopyToClipboard></span>
}

function getLogoCell(item, entity) {
    return <Link to={`/${entity}/${item.id}/${item.name}`}>
        <div className="logo-container"><div className="logo" style={{backgroundImage: `url(${item.image})`}} /></div>
    </Link>
}

function dateDiff(item) {
    const addedAt = moment(item.added_at),
        removedAt = moment(item.removed_at || undefined);
    return Math.round(moment.duration(removedAt.diff(addedAt)).as('days'));
}

function logoLink(item, entity) {
    if (entity == 'playlists')
        return <a href={`/${entity}/${item.id}/details`}>
            <div
                className="logo"
                style={{ backgroundImage: `url(${item.image})` }}
            />
        </a>;
    else
        return <a href={`/${entity}/${item.id}`}>
            <div
                className="logo"
                style={{ backgroundImage: `url(${item.image})` }}
            />
        </a>;
}


function matchMedia() {
    let widgetMode = 'expanded';
    let hasTouchScreen = false;

    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone)\b/i.test(UA);
      }
    }

    return hasTouchScreen;
}

function settingsForTable(mode, widgetMode = 'expanded', externalLink = true) {
    
    const Link = externalLink ? LiveLink : ReadonlyLink;
    let modes = {};

    if (window.matchMedia("(max-width: 600px)").matches) 
        widgetMode = 'collapsed'

    if (widgetMode == 'collapsed' || widgetMode == 'normal') {
        modes = {
            'imprints': ['logo', 'imprint_name_mob', 'curr_units_mob', 'passive_mob', 'engaged_mob', 'skipped_ratio_mob', 'curr_playlists_mob' ],
            'products': ['logo', 'product_title_mob', 'curr_units_mob', 'passive_mob', 'engaged_mob', 'skipped_ratio_mob', 'curr_playlists_mob', 'free', 'discounted', 'premium' ],
            'projects': ['logo', 'project_title_mob', 'curr_units_mob', 'passive_mob', 'engaged_mob', 'skipped_ratio_mob', 'curr_playlists_mob' ],
            'artists': ['logo', 'artist_name_mob', 'curr_units_mob', 'passive_mob', 'engaged_mob', 'skipped_ratio_mob', 'curr_playlists_mob' ],
            'tracks': ['logo', 'tracks_mob', 'curr_units_mob', 'passive_mob', 'engaged_mob', 'skipped_ratio_mob', 'curr_playlists_mob', 'duration_time_mob', 'tiktok_mob', 'audio_format_mob', 'release_date', 'audio_compressed', 'audio_lossless', 'audio_immersive', 'free', 'discounted', 'premium', 'lyrics_viewed', 'canvas_viewed' ],
            'playlists': ['logo', 'playlist_name_mob', 'curr_units_mob', 'skipped_ratio', 'completed_ratio', 'total_tracks', 'playlist_share'],
            // 'platforms': ['logo', 'name', 'curr_units', 'units_diff', 'passive', 'active', 'engaged', 'curr_tracks', 'playlist_share'],
        }
    } else {
        modes = {
            'imprints': ['logo', 'imprint_name', 'curr_units', 'units_diff', 'passive', 'active', 'engaged', 'skipped_ratio', 'completed_ratio', 'curr_playlists', 'playlists_diff' ],
            'products': ['logo', 'product_title', 'upc', 'curr_units', 'units_diff', 'passive', 'active', 'engaged', 'skipped_ratio', 'completed_ratio', 'curr_playlists', 'playlists_diff', 'release_date', 'pre_add', 'free', 'discounted', 'premium' ],
            'projects': ['logo', 'project_title', 'curr_units', 'units_diff', 'passive', 'active', 'engaged', 'skipped_ratio', 'completed_ratio', 'curr_playlists', 'playlists_diff', 'release_date'],
            'artists': ['logo', 'artist_name', 'curr_units', 'units_diff', 'passive', 'active', 'engaged', 'skipped_ratio', 'completed_ratio', 'curr_playlists', 'playlists_diff'],            
            'tracks': ['logo', 'track_name', 'isrc', 'curr_units', 'units_diff', 'passive', 'active', 'engaged', 'skipped_ratio', 'completed_ratio', 'curr_playlists', 'playlists_diff', 'duration_time', 'release_date', 'audio_compressed', 'audio_lossless', 'audio_immersive', 'free', 'discounted', 'premium', 'lyrics_viewed', 'canvas_viewed' ],
            'playlists': ['logo', 'playlist_name', 'curr_units', 'units_change', 'skipped_ratio', 'completed_ratio', 'total_tracks', 'playlist_share']
        }
    }

    let columns = [
        {
            id: "logo",
            className: "logo-sell",
            headerClassName: "logo-sell no-sort",
            Header: "",
            accessor: "image",
            width: 60,
            sortable: false,
            Cell: (props) => {
                const onClickCall = (e) => {
                    return onTitleClick(props.original);
                };
                return (
                    <div className="logo-container">
                       {logoLink(props.original, mode)}
                    </div>
                );
            },
            Footer: spaceFooter
        },
        {
            id: "artist_name",
            className: "imprint-name",
            headerClassName: "imprint-name no-sort",
            Header: "Artist",
            accessor: "artist_name",
            sortable: false,
            width: 260,
            Cell: props => {
                return <span>{props.original.artist_id ? <Link to={`/artists/${props.original.artist_id}`}>{props.value}</Link> : props.value}</span>
            },
            Footer: textFooter
        },
        {
            id: "track_name",
            className: "imprint-name",
            headerClassName: "imprint-name no-sort",
            Header: "Track",
            accessor: "track_title",
            sortable: false,
            width: 260,
            Cell: props => {
                return <span><span><b>{props.original.id ? <Link to={`/tracks/${props.original.id}`}>{props.value}</Link> : props.value}</b></span>
                By: {props.original.artist_name}
                <br/>
                <span className="isrc-holder with-copy">
                {props.original.id ? <CopyToClipboard value={props.original.isrc}>ISRC: {props.original.isrc}</CopyToClipboard> : <span>ISRC: {props.original.isrc}</span>}</span>
                {/* Duration: {getTime(props.original.duration)} */}
                </span>
            },
            Footer: spaceFooter
        },
        {
            id: "imprint_name",
            className: "imprint-name",
            headerClassName: "imprint-name no-sort",
            Header: "Imprint",
            accessor: "imprint_name",
            sortable: false,
            width: 260,
            Cell: props => {
                const onClickCall = (e)=>{return onTitleClick(props.original);};
                return <span>{props.original.id ? <Link to={`/imprints/${props.original.id}`}>{props.value}</Link> : props.value}</span>
            },
            Footer: textFooter
        },
        
        {
            id: 'playlist_name',
            className: 'playlist-name-total',
            headerClassName: 'playlist-name-total no-sort',
            Header: 'Playlist',
            accessor: 'playlist_name',
            Cell: props => <div className="logo-container">
                <div className="playlist-description">
                      <p className="playlist-name" title={props.value}>
                          <VendorLogo name={props.original.vendor} modificator="small"/><span className={`activity activity-${props.original.pl_active?'true':'false'}`}/><Link to={`/playlists/${props.original.playlist_id}/details`}>{props.value}</Link></p>
                </div>                
            </div>,
            Footer: textFooter
        },  
        {
            id: "playlist_name_mob",
            className: "playlist-name-total imprint-name-mob",
            headerClassName: "playlist-name-total imprint-name-mob no-sort",
            Header: "Title",
            accessor: "playlist_name",
            sortable: false,
            width: 200,
            Cell: props => <div className="logo-container">
                <div className="playlist-description">
                      <p className="playlist-name" title={props.value}>
                          <VendorLogo name={props.original.vendor} modificator="small"/><span className={`activity activity-${props.original.pl_active?'true':'false'}`}/><Link to={`/playlists/${props.original.playlist_id}/details`}>{props.value}</Link></p>
                </div>                
            </div>,
            Footer: textFooter
        }, 

        {
            id: "product_title",
            className: "imprint-name",
            headerClassName: "imprint-name no-sort",
            Header: "Product",
            accessor: "name",
            sortable: false,
            width: 260,
            Cell: (props) => {
            const onClickCall = (e) => {
                return onTitleClick(props.original);
            };
            return <span><span><b>{props.original.id ? <Link to={`/products/${props.original.id}`}>{props.value}</Link> : props.value}</b></span>
            By: {props.original.artist_name}
            <br/>
            <span className="isrc-holder with-copy">
            {props.original.id ? <CopyToClipboard value={props.original.upc}>UPC: {props.original.upc}</CopyToClipboard> : <span>UPC: {props.original.upc}</span>}</span>
            </span>

            },
            Footer: spaceFooter
        },
        {
            id: "project_title",
            className: "imprint-name",
            headerClassName: "imprint-name no-sort",
            Header: "Project",
            accessor: "name",
            sortable: false,
            width: 260,
            Cell: (props) => {
            const onClickCall = (e) => {
                return onTitleClick(props.original);
            };
            return <span><span><b>{props.original.id ? <Link to={`/projects/${props.original.id}`}>{props.value}</Link> : props.value}</b></span>
            By: {props.original.artist_name}
            <br/>
            <span className="isrc-holder with-copy">
            {props.original.id ? <CopyToClipboard value={props.original.code}>Code: {props.original.code}</CopyToClipboard> : <span>Code: {props.original.code}</span>}</span>
            </span>

            },
            Footer: spaceFooter
        },        
        /*
        {
            id: "upc",
            className: "isrc-cell",
            headerClassName: "left-text",
            Header: "UPC",
            accessor: "upc",
            width: 160,
            Cell: (props) => {
                return <span className="isrc-holder with-copy">{props.value}
                {props.original.id ? <CopyToClipboard value={props.value} /> : null}</span>;
            },
            Footer: spaceFooter
        },
        
        {
            id: "isrc",
            className: "isrc-cell",
            headerClassName: "left-text",
            Header: "ISRC",
            accessor: "isrc",
            width: 160,
            Cell: (props) => {
                return <span className="isrc-holder with-copy">{props.value}
                {props.original.id ? <CopyToClipboard value={props.value} /> : null}</span>;
            },
            Footer: spaceFooter
        },
        */
        {
            id: 'curr_units',
            className: 'lowercase',
            Header: 'Units',
            Cell: props => <span>{splitByThousands(props.value)}</span>,
            accessor: 'curr_units',
            Footer: convertSumFooter
        },
        {
            id: "units_diff",
            className: "lowercase",
            Header: <p>Unit Change</p>,
            Cell: (props) => {
                const { units_change, units_diff } = props.original;
                const direction = units_diff > 0 ? "up" : "down";
                const offset = units_diff > 0 ? 'offset' : '';
                return <span className={`vendor-value vendor-value-inline ${offset}`}>{splitByThousands(units_change)} {units_diff > 0 && <span className={direction}>{round(units_diff)}%</span>} </span>
            },
            accessor: 'units_diff',
            Footer: spaceFooter,
            width: 130
        },
        {
            id: 'passive',
            headerClassName: '',
            Header: props => <div className="top-table-header-inner">
                Passive Units
                <div className="table-header-tooltip">
                     <Tooltip position="bottom" message={`Passive Units: editorial, third party and algorithmic playlists, radio, and other (such as the Spotify autoplay feature). This may also include streams from a playlist with the artist name (This is, Essentials…) where the artist is not the main primary artist on the track, like featuring or remixes for instance.`} tooltipClass="toolbar-title-tooltip"/>
                 </div>
             </div>,
            accessor: 'passive',
            Cell: props => props.value ? <span>{splitByThousands(props.value)}</span> : '-',
            Footer: convertSumFooter,
            width: 120                 
        },
        
        {
            id: 'active',
            headerClassName: '',
            Header: props => <div className="top-table-header-inner">
                Active Units
                <div className="table-header-tooltip">                     
                     <Tooltip position="bottom" message={`Active Units: streams coming from search, artist page, album page, collections, and playlists with the artist name in it (This is, Essentials…)`} tooltipClass="toolbar-title-tooltip"/>
                 </div>
             </div>,
            accessor: 'active',
            Cell: props => props.value ? <span>{splitByThousands(props.value)}</span> : '-',
            Footer: convertSumFooter,
            width: 120                 
        },
        {
            id: 'engaged',
            headerClassName: '',
            Header: 'Engaged',
            accessor: 'engaged',
            Cell: props => <span>{props.value}%</span>,
            Footer: avgFooter
        },
        {
            id: 'curr_playlists',
            className: 'lowercase grid-td playlists',
            Header: 'Playlists',
            accessor: 'curr_playlists',
            Cell: props => <span>{splitByThousands(props.value)}</span>,
            Footer: convertSumFooter
        },
        {
            id: 'playlists_diff',
            className: 'lowercase grid-td',
            Header: 'Playlists Growth',
            accessor: 'playlists_diff',
            Cell: props => <span>{props.value}%</span>,
            Footer: avgFooter
        },
        {
            id: 'duration_time',
            headerClassName: 'no-sort',
            className: '',
            Header: 'Duration',
            Cell: props => <span>{getTime(props.value)}</span>,
            accessor: 'duration',
            sortable: false,
            Footer: spaceFooter
        },    
        {
            id: 'tiktokCreates',
            headerClassName: '',
            Header: props => <div className="top-table-header-inner">
                TikTok Creates
                <div className="table-header-tooltip">
                     <Tooltip position="bottom" message={`Number of videos created using this audio`} tooltipClass="toolbar-title-tooltip"/>
                 </div>
             </div>,
            accessor: 'tiktokCreates',
            Cell: props => props.value ? <span>{splitByThousands(props.value)}</span> : '-',
            Footer: convertSumFooter,
            width: 130
        },        
        {
            id: 'tiktokViews',
            headerClassName: '',
            Header: props => <div className="top-table-header-inner">
                TikTok Views
                <div className="table-header-tooltip">
                     <Tooltip position="bottom" message={`Views of TikTok Creates`} tooltipClass="toolbar-title-tooltip"/>
                 </div>
             </div>,
            accessor: 'tiktokViews',
            Cell: props => props.value ? <span>{splitByThousands(props.value)}</span> : '-',
            Footer: convertSumFooter,
            width: 130                 
        },
        {
            id: 'audio_compressed',
            headerClassName: '',
            Header: props => <div className="top-table-header-inner">
                Compressed
                <div className="table-header-tooltip">
                     <Tooltip position="bottom" message={`Audio format represents the quality of the audio streamed and is available from Apple and Amazon only. Compressed (mp3 and other formats)`} tooltipClass="toolbar-title-tooltip"/>
                 </div>
             </div>,
            accessor: 'audio_compressed',
            Cell: props => props.value ? <span>{splitByThousands(props.value)}</span> : '-',
            width: 135,
            Footer: convertSumFooter
        },                                         
        {
            id: 'audio_lossless',
            headerClassName: '',
            Header: props => <div className="top-table-header-inner">
                Lossless
                <div className="table-header-tooltip">
                     <Tooltip position="bottom" message={`Audio format represents the quality of the audio streamed and is available from Apple and Amazon only. Lossless (CD quality and high res)`} tooltipClass="toolbar-title-tooltip"/>
                 </div>
             </div>,
            accessor: 'audio_lossless',
            Cell: props => props.value ? <span>{splitByThousands(props.value)}</span> : '-',
            Footer: convertSumFooter,
            width: 130
        },    
        {
            id: 'audio_immersive',
            headerClassName: '',
            Header: props => <div className="top-table-header-inner">
                Immersive
                <div className="table-header-tooltip">
                     <Tooltip position="bottom" message={`Audio format represents the quality of the audio streamed and is available from Apple and Amazon only. Immersive (spatial and 360 audio)`} tooltipClass="toolbar-title-tooltip"/>
                 </div>
             </div>,
            accessor: 'audio_immersive',
            Cell: props => props.value ? <span>{splitByThousands(props.value)}</span> : '-',
            width: 130,
            Footer: convertSumFooter
        },  
        
        {
            id: 'lyrics_viewed',
            headerClassName: '',
            Header: 'Spotify Lyrics Viewed',
            accessor: 'lyrics_viewed',
            Cell: props => props.value ? <span>{splitByThousands(props.value)}</span> : '-',
            width: 135,
            Footer: convertSumFooter
        },                                         
        {
            id: 'canvas_viewed',
            headerClassName: '',
            Header: 'Spotify Canvas Viewed',
            accessor: 'canvas_viewed',
            Cell: props => props.value ? <span>{splitByThousands(props.value)}</span> : '-',
            width: 135,
            Footer: convertSumFooter
        },                                         

                          
        // settings for mobiles
        {
            id: "product_title_mob",
            className: "imprint-name imprint-name-mob",
            headerClassName: "imprint-name imprint-name-mob no-sort",
            Header: "Title",
            accessor: "name",
            sortable: false,
            width: 200,
            Cell: (props) => {
            const onClickCall = (e) => {
                return onTitleClick(props.original);
            };
            return <span><span><b>{props.original.id ? <Link to={`/products/${props.original.id}`}>{props.value}</Link> : props.value}</b></span>
            By: {props.original.artist_name}
            <br/>
            <span className="isrc-holder with-copy">
            {props.original.id ? <CopyToClipboard value={props.original.upc}>UPC: {props.original.upc}</CopyToClipboard> : <span>UPC: {props.original.upc}</span>}</span>
            </span>
            },
            Footer: textFooter
        },
        // settings for mobiles
        {
            id: "project_title_mob",
            className: "imprint-name imprint-name-mob",
            headerClassName: "imprint-name imprint-name-mob no-sort",
            Header: "Title",
            accessor: "name",
            sortable: false,
            width: 200,
            Cell: (props) => {
            const onClickCall = (e) => {
                return onTitleClick(props.original);
            };
            return <span><span><b>{props.original.id ? <Link to={`/projects/${props.original.id}`}>{props.value}</Link> : props.value}</b></span>
            By: {props.original.artist_name}
            <br/>
            <span className="isrc-holder with-copy">
            {props.original.id ? <CopyToClipboard value={props.original.code}>Project: {props.original.code}</CopyToClipboard> : <span>Project: {props.original.code}</span>}</span>
            </span>
            },
            Footer: textFooter
        },        
        
        {
            id: "artist_name_mob",
            className: "imprint-name imprint-name-mob",
            headerClassName: "imprint-name imprint-name-mob no-sort",
            Header: "Title",
            accessor: "artist_name",
            sortable: false,
            width: 200,
            Cell: props => {
                return <span>{props.original.artist_id ? <Link to={`/artists/${props.original.artist_id}`}>{props.value}</Link> : props.value}</span>
            },
            Footer: textFooter
        },
        {
            id: "tracks_mob",
            className: "imprint-name imprint-name-mob",
            headerClassName: "imprint-name imprint-name-mob no-sort",
            Header: "Title",
            accessor: "track_title",
            sortable: false,
            width: 200,
            Cell: props => {
                return <span><span><b>{props.original.id ? <Link to={`/tracks/${props.original.id}`}>{props.value}</Link> : props.value}</b></span>
                By: {props.original.artist_name}
                <br/>
                <span className="isrc-holder with-copy">
                {props.original.id ? <CopyToClipboard value={props.original.isrc}>ISRC: {props.original.isrc}</CopyToClipboard> : <span>ISRC: {props.original.isrc}</span>}</span>
                {/* Duration: {getTime(props.original.duration)} */}
                </span>
            },
            Footer: textFooter
        },  
        {
            id: "imprint_name_mob",
            className: "imprint-name imprint-name-mob",
            headerClassName: "imprint-name imprint-name-mob no-sort",
            Header: "Title",
            accessor: "imprint_name",
            sortable: false,
            width: 200,
            Cell: props => {
                const onClickCall = (e)=>{return onTitleClick(props.original);};
                return <span>{props.original.id ? <Link to={`/imprints/${props.original.id}`}>{props.value}</Link> : props.value}</span>
            },
            Footer: textFooter
        },
        {
            id: 'curr_units_mob',
            className: 'lowercase text-align-right',
            headerClassName: "text-align-right",
            Header: props => <span>Units<br/>Unit Change</span>,
            Cell: props => {
                const { percentageValue } = props.original;
                return <span>{splitByThousands(props.value)}<br />
                {percentageValue && <span>{round(percentageValue)}%</span>}
                </span>
            },
            accessor: 'curr_units',
            width: 160,
            Footer: convertSumFooter
        },
        {
            id: 'skipped_ratio',
            accessor: 'skipped_ratio',
            className: 'lowercase grid-td',
            headerAlt: 'Skip Rate',
            Header: props => <div className="top-table-header-inner">
                Skip Rate A/V
                <div className="table-header-tooltip">
                    <Tooltip position="bottom" message={`The Skip rate only applies to streams over 30 seconds. It's a metric provided by all DSPs except YouTube and Google. A - Audio, V - Video`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,
            Cell: ({original}) => <span>{original.skipped_audio_ratio}% / {original.skipped_video_ratio}%</span>,
            width: 135,
            Footer: (props)=><React.Fragment>{avgFooter(props, null, 'skipped_audio_ratio')} / {avgFooter(props, null, 'skipped_video_ratio')}</React.Fragment>
        },
        {
            id: 'completed_ratio',
            accessor: 'completed_ratio',
            className: 'lowercase grid-td',
            headerAlt: 'Completion Rate',
            Header: props => <div className="top-table-header-inner">
                Completion Rate A/V
                <div className="table-header-tooltip">
                    <Tooltip position="right-bottom" message={`A track is marked as complete when the stream reached 95% of the track length. It's a metric provided by all DSPs except YouTube and Google. A - Audio, V - Video`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,
            Cell: ({original}) => <span>{original.completed_audio_ratio}% / {original.completed_video_ratio}%</span>,
            width: 135,
            Footer: (props)=><React.Fragment>{avgFooter(props, null, 'completed_audio_ratio')} / {avgFooter(props, null, 'completed_video_ratio')}</React.Fragment>
        },
        
        {
            id: 'total_tracks',
            headerClassName: '',
            className: '',
            Header: 'Tracks',
            Cell: props => <span>{props.value ? props.value : 'N/A'}</span>,
            accessor: 'total_tracks',
            width: 120,
            Footer: convertSumFooter
        },
        {
            id: 'playlist_share',
            headerClassName: '',
            className: '',
            Header: props => <div className="top-table-header-inner">
                Playlist Share
                <div className="table-header-tooltip">
                    <Tooltip position="right-bottom" message={`Only available from Spotify, Apple and Deezer. Over 100% share indicates tracks that were removed or added over the course of your date range..`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,
            Cell: props => <span>{props.value ? `${props.value}%` : 'N/A'}</span>,
            accessor: 'playlist_share',
            width: 120,
            Footer: avgFooter
        }, 

        {
            id: 'passive_mob',
            className: 'text-align-right',
            headerClassName: 'text-align-right',
            Header: props => <div>
                <div className="top-table-header-inner">
                    Passive Streams
                    <div className="table-header-tooltip">
                        <Tooltip position="bottom" message={`Passive Streams: editorial, third party and algorithmic playlists, radio, and other (such as the Spotify autoplay feature). This may also include streams from a playlist with the artist name (This is, Essentials…) where the artist is not the main primary artist on the track, like featuring or remixes for instance.`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>
                <div className="top-table-header-inner">
                    Active Streams
                    <div className="table-header-tooltip">                     
                        <Tooltip position="bottom" message={`Active Streams: streams coming from search, artist page, album page, collections, and playlists with the artist name in it (This is, Essentials…)`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>
            </div>,
            accessor: 'passive',
            Cell: props =>
            <React.Fragment>
                { props.value ? <span>{splitByThousands(props.value)}</span> : '-' }
                <br /> 
                { props.original.active ? <span>{splitByThousands(props.original.active)}</span> : '-' }
            </React.Fragment>,
            width: 180,
            Footer: (props)=><React.Fragment>{convertSumFooter(props, null, 'passive')} <br /> {convertSumFooter(props, null, 'active')}</React.Fragment>
        },

        {
            id: 'engaged_mob',
            headerClassName: 'text-align-right',
            className: 'text-align-right',
            Header: 'Engaged',
            accessor: 'engaged',
            Cell: props => <span>{props.value}%</span>,
            width: 120,
            Footer: avgFooter
        },
        {
            id: 'skipped_ratio_mob',
            accessor: 'skipped_ratio',
            className: 'lowercase grid-td text-align-right',
            headerClassName: 'text-align-right',
            headerAlt: 'Skip Rate',
            Header: props => <div>
                <div className="top-table-header-inner">
                    Skip Rate A/V
                    <div className="table-header-tooltip">
                        <Tooltip position="bottom" message={`The Skip rate only applies to streams over 30 seconds. It's a metric provided by all DSPs except YouTube and Google. A - Audio, V - Video`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>
                <div className="top-table-header-inner">
                    Completion Rate A/V
                    <div className="table-header-tooltip">
                        <Tooltip position="right-bottom" message={`A track is marked as complete when the stream reached 95% of the track length. It's a metric provided by all DSPs except YouTube and Google. A - Audio, V - Video`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>
            </div>,
            Cell: ({original}) => <span>{original.skipped_audio_ratio}% / {original.skipped_video_ratio}% <br />{original.completed_audio_ratio}% / {original.completed_video_ratio}%</span>,
            width: 190,
            Footer: (props)=><React.Fragment>{avgFooter(props, null, 'skipped_audio_ratio')} / {avgFooter(props, null, 'skipped_video_ratio')} <br /> {avgFooter(props, null, 'completed_audio_ratio')} / {avgFooter(props, null, 'completed_video_ratio')}</React.Fragment>
        },
        {
            id: 'curr_playlists_mob',
            className: 'lowercase grid-td playlists text-align-right',
            headerClassName: 'text-align-right',
            accessor: 'curr_playlists',
            Header: props => <span>Playlists<br/>Playlists Growth</span>,
            Cell: props => <span>{splitByThousands(props.value)}<br/>{props.original.playlists_diff}</span>,
            width: 150,
            Footer: (props)=><React.Fragment>{convertSumFooter(props, null, 'curr_playlists')} <br /> {avgFooter(props, null, 'playlists_diff')} </React.Fragment>
        },
        {
            id: 'duration_time_mob',
            headerClassName: 'no-sort text-align-right',
            className: 'text-align-right',
            Header: 'Duration',
            Cell: props => <span>{getTime(props.value)}</span>,
            accessor: 'duration',
            sortable: false,
        },
        {
            id: 'release_date',
            headerClassName: 'no-sort',
            className: '',
            Header: 'Release Date',
            Cell: props => <span>{props.value}</span>,
            accessor: 'release_date',
            sortable: false,
            width: 120,
            Footer: spaceFooter
        }, 
        {
            id: 'pre_add',
            headerClassName: 'no-sort',
            className: '',
            Header: 'PreAdd',
            Cell: props => <span>{props.value}</span>,
            accessor: 'pre_add',
            sortable: false,
            width: 100,
            Footer: convertSumFooter
        }, 
        
        {
            id: 'tiktok_mob',
            className: 'text-align-right',
            headerClassName: 'text-align-right',
            Header: props => <div>
                <div className="top-table-header-inner">
                    TikTok Creates
                    <div className="table-header-tooltip">
                        <Tooltip position="bottom" message={`Number of videos created using this audio`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>
                <div className="top-table-header-inner">
                    TikTok Views
                    <div className="table-header-tooltip">                     
                        <Tooltip position="bottom" message={`Views of TikTok Creates"`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>
            </div>,
            accessor: 'tiktok_creates',
            Cell: ({original}) =>
            <React.Fragment>
                { original.tiktokCreates ? <span>{splitByThousands(original.tiktokCreates)}</span> : '-' }
                <br /> 
                { original.tiktokViews ? <span>{splitByThousands(original.tiktokViews)}</span> : '-' }
            </React.Fragment>,
            width: 130,
            Footer: (props)=><React.Fragment>{convertSumFooter(props, null, 'tiktokViews')} <br /> {convertSumFooter(props, null, 'tiktokCreates')}</React.Fragment>
        },
        {
            id: 'audio_format_mob',
            className: 'text-align-right',
            headerClassName: 'text-align-right',
            Header: props => <div>
                <div className="top-table-header-inner">
                    Compressed
                    <div className="table-header-tooltip">
                        <Tooltip position="bottom" message={`Audio format represents the quality of the audio streamed and is available from Apple and Amazon only. Compressed (mp3 and other formats`}/>
                    </div>
                </div>
                <div className="top-table-header-inner">
                    Lossless
                    <div className="table-header-tooltip">                     
                        <Tooltip position="bottom" message={`Audio format represents the quality of the audio streamed and is available from Apple and Amazon only. Lossless (CD quality and high res)`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>
                <div className="top-table-header-inner">
                    Immersive
                    <div className="table-header-tooltip">                     
                        <Tooltip position="bottom" message={`Audio format represents the quality of the audio streamed and is available from Apple and Amazon only. Immersive (spatial and 360 audio)`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>
            </div>,
            accessor: 'audio_compressed',
            Cell: ({original}) =>
            <React.Fragment>
                { original.audio_compressed }
                <br /> 
                { original.audio_lossless }
                <br />
                { original.audio_immersive }
            </React.Fragment>,
            width: 130,
            Footer: (props)=><React.Fragment>{convertSumFooter(props, null, 'audio_compressed')} <br /> {convertSumFooter(props, null, 'audio_lossless')} <br /> {convertSumFooter(props, null, 'audio_immersive')}</React.Fragment>
        },       
        {
            id: 'free',
            className: 'lowercase',
            headerClassName: "",
            Header: props => <span>Free</span>,
            Cell: props => <span>{props.value}</span>,
            accessor: 'free',
            // width: 160,
            Footer: convertSumFooter
        },      
        {
            id: 'discounted',
            className: 'lowercase',
            headerClassName: "",
            Header: props => <span>Discounted</span>,
            Cell: props => <span>{props.value}</span>,
            accessor: 'discounted',
            // width: 160,
            Footer: convertSumFooter
        },      
        {
            id: 'premium',
            className: 'lowercase',
            headerClassName: "",
            Header: props => <span>Premium</span>,
            Cell: props => <span>{props.value}</span>,
            accessor: 'premium',
            // width: 160,
            Footer: convertSumFooter
        },      
    ];

    
    columns = columns.map(column=>{
        column.show = modes[mode].includes(column.id);
        return column;
    });
    
    //     const reducedColumns = ['logo', 'imprint_name', 'artist_name', 'product_title', 'track_name', 'playlist_name', 'curr_units', 'curr_playlists'];
    // if (widgetMode == 'normal') {
    //     columns = columns.map(column=>{
    //         if (column.show){
    //             column.show = reducedColumns.includes(column.id);
    //         }
    //         return column;
    //     });        
    // }
    return columns;
}



export default function EntityTable(props){
    const [selectedRows, setSelectedRows] = useState([]);
    const allRows = map(props.results, 'id');
    const { page = 0, total, onLoad, sort, manual = false } = props;
    const pageSize = 10;
    const pages = Math.ceil(total / pageSize);
    const sorted = sort ? [{id: sort.field, desc: sort.dir == 'DESC'}] : [];
    
    const toggleSelection = (id) => {
        let rows = Object.assign([], selectedRows);
        if(rows.includes(id))
            rows = rows.filter(item=>item!=id);
        else
            rows.push(id);
        setSelectedRows(rows);
    }
    
    const allSelected = () => {
        if(!allRows)
            return false;
        return (selectedRows.length > 0 && selectedRows.length == allRows.length);
    }

    const toggleAll = () => {
        const selected = allSelected() ? [] : allRows;
        setSelectedRows(selected);
    }
    
    const renderGoToLink = () => {
        const { entity } = props;
        const selected = selectedRows.length;
        if(!selected)
            return null;
        const filter = JSON.stringify({[entity]: [selectedRows]});
        return <p><a className="default-btn default-btn--small default-btn--light go-to-link-btn" target="_blank" href={`${config.apis.base}/filter/${filter}/overview?filtered`}>filter on {selected} {selected == 1 ? entity.slice(0, -1) : entity}</a></p>
    }
    
    const TableComponent = props.entity == 'tracks' ? SelectTable : CellHeaderTable;
    
    const tableProps = manual ? {
        manual: true,
        showPagination: pages > 1,
        page: page,
        pages: pages,
        onFetchData: onLoad
    } : {
        showPagination: false,
    }
    
    return <React.Fragment>
     <TableComponent
        data={props.results}
        columns={settingsForTable(props.entity, props.widgetMode, props.externalLinks)}
        pageSize={Math.min(props.results.length, 10)}
        showPageSize={false}
        loading={props.resultsLoading}
        className="simple-table"
        noDataText='No results found. Please check your filters.'
        keyField="id"
        toggleSelection={(key, shift, row)=>{toggleSelection(row.id)}}
        isSelected={(key)=>{return selectedRows.includes(key)}}
        selectAll={allSelected()}
        toggleAll={toggleAll}
        defaultSorted={sorted}
        {...tableProps}
    /> 
    {renderGoToLink()}
    </React.Fragment>
}
