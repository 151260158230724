import { find, isEqual } from 'lodash';
import { userEntityFiltersService } from '../services/user_entity_filters';
import { userEntityFiltersFormatter } from '../formatters/user_entity_filters';
import { userEntityFiltersConstants } from '../constants/user_entity_filters';

export const userEntityFiltersActions = {
  getUserEntityFilter,
  getUserEntityFilters,
  getPlaylistEntityFilters,
  updateUserEntityFilter,
  createFilter,
  destroyFilter,
  updateFilterEntities,
  addRecentFilter,
  getRecentEntityFilters,
  clearRecentEntityFilters,
}

function destroyFilter(id, recent = false){
  return (dispatch, getState) => {
    dispatch(request());

    return userEntityFiltersService.destroyFilter(id)
    .then( response => {
      dispatch(success());
      if(!recent)
          dispatch(getUserEntityFilters());
      return true
    })
    .catch( error => {
      dispatch(failure(error));
    });
  }

  function request() { return { type: userEntityFiltersConstants.DESTROY_USER_ENTITY_FILTER_REQUEST }};
  function success() { return { type: userEntityFiltersConstants.DESTROY_USER_ENTITY_FILTER_SUCCESS }};
  function failure(error) { return { type: userEntityFiltersConstants.DESTROY_USER_ENTITY_FILTER_FAILURE, error }};
}

function createFilter(params){
  return dispatch => {
    dispatch(request());
    params = userEntityFiltersFormatter.formatNewFilter(params);
    return userEntityFiltersService.createFilter(params)
    .then( response => {
      dispatch(success(response.data));
      dispatch(getUserEntityFilters());
      return response.data;
    })
    .catch( error => {
      dispatch(failure(error));
    })
  }

  function request() { return { type: userEntityFiltersConstants.CREATE_USER_ENTITY_FILTER_REQUEST }};
  function success(payload, userEntity) { return { type: userEntityFiltersConstants.CREATE_USER_ENTITY_FILTER_SUCCESS, payload, userEntity}};
  function failure(payload) { return { type: userEntityFiltersConstants.CREATE_USER_ENTITY_FILTER_FAILURE, payload }};
}

function getUserEntityFilters(useCache = false){
  return (dispatch, getState) => {
    dispatch(request());
    const currentUser = getState().user.user;
    const userFilters = getState().userEntityFilters.filters;
    
    if(useCache && userFilters) {
        dispatch(success(userFilters));
        return Promise.resolve(userFilters);
    }
    return userEntityFiltersService.getUserEntityFilters()
    .then( response => {
        const { filters, playlistFilters } = userEntityFiltersFormatter.formatUserEntityFilters(response, currentUser);
        dispatch(success(filters, playlistFilters));
        return filters;
    })
    .catch( error => {
      dispatch(failure(error));
    })
  }
  function request() { return { type: userEntityFiltersConstants.GET_USER_ENTITY_FILTERS_REQUEST }};
  function success(filters, playlistFilters) { return { type: userEntityFiltersConstants.GET_USER_ENTITY_FILTERS_SUCCESS, filters, playlistFilters }};
  function failure(error) { return { type: userEntityFiltersConstants.GET_USER_ENTITY_FILTERS_FAILURE, error }};
}

function getUserEntityFilter(id){
    return dispatch => {
      dispatch(request());

      return userEntityFiltersService.getUserEntityFilter(id)
      .then( response => {
          //const filter = userEntityFiltersFormatter.formatUserEntityFilter(response);
          const filter = response;
          dispatch(success(filter.data));
          return filter.data;
      })
      .catch( error => {
        dispatch(failure(error));
      })
    }
    function request() { return { type: userEntityFiltersConstants.GET_USER_ENTITY_FILTER_REQUEST }};
    function success(filter) { return { type: userEntityFiltersConstants.GET_USER_ENTITY_FILTER_SUCCESS, filter }};
    function failure(error) { return { type: userEntityFiltersConstants.GET_USER_ENTITY_FILTER_FAILURE, error }};
  }


function updateUserEntityFilter(id, params, reload = true){
  return dispatch => {
    dispatch(request());

    return userEntityFiltersService.updateUserEntityFilter(id, params)
    .then( response => {
      const filter = response.data;
      dispatch(success(filter));
      if(reload)
        dispatch(getUserEntityFilters());
      return filter;
    })
    .catch( error => {
      dispatch(failure(error));
    });
  }
  function request() { return { type: userEntityFiltersConstants.UPDATE_USER_ENTITY_FILTER_REQUEST }};
  function success(filter) { return { type: userEntityFiltersConstants.UPDATE_USER_ENTITY_FILTER_SUCCESS, filter}};
  function failure(error) { return { type: userEntityFiltersConstants.UPDATE_USER_ENTITY_FILTER_FAILURE, error}};
}

function getPlaylistEntityFilters(useCache = true){
    return (dispatch, getState) => {
      dispatch(request());
	  const currentUser = getState().user.user;
      const playlistFilters = getState().userEntityFilters.playlistFilters;
      if(useCache && playlistFilters) {
          dispatch(success(playlistFilters));
          return Promise.resolve(playlistFilters);
      }

      return userEntityFiltersService.getPlaylistEntityFilters()
      .then( response => {
        const { filters, playlistFilters } = userEntityFiltersFormatter.formatUserEntityFilters(response, currentUser);
        dispatch(success(playlistFilters));
      })
      .catch( error => {
        dispatch(failure(error));
      })
    }
    function request(userEntity) { return { type: userEntityFiltersConstants.GET_PLAYLIST_ENTITY_FILTERS_REQUEST, userEntity }};
    function success( filters ) { return { type: userEntityFiltersConstants.GET_PLAYLIST_ENTITY_FILTERS_SUCCESS, filters }};
    function failure( payload ) { return { type: userEntityFiltersConstants.GET_PLAYLIST_ENTITY_FILTERS_FAILURE, error: payload }};
  }


function updateFilterEntities(id, entitiesToAdd, entitiesToDelete, reload = true){
    return dispatch => {
      dispatch(request());
      
      let requests = [];
      for(let entity of entitiesToAdd){
          requests.push(userEntityFiltersService.addToFilter(id, entity));
      }
      for(let entity of entitiesToDelete){
          requests.push(userEntityFiltersService.deleteFromFilter(id, entity.id));
      }
      
      const afterUpdate = reload ? dispatch(getUserEntityFilters(false)) : Promise.resolve([]);

      return Promise.all(requests)
      .then(()=> afterUpdate
          .then( filters => {
            dispatch(success());
            return find(filters, filter=>filter.id == id);
      }))
      .catch( error => {
        dispatch(failure(error, userEntity));
      });
    }
    function request() { return { type: userEntityFiltersConstants.UPDATE_USER_ENTITY_FILTER_REQUEST }};
    function success() { return { type: userEntityFiltersConstants.UPDATE_USER_ENTITY_FILTER_SUCCESS}};
    function failure(error) { return { type: userEntityFiltersConstants.UPDATE_USER_ENTITY_FILTER_FAILURE, error}};
  }


function addRecentFilter(metadata, title){
    return (dispatch, getState) => {
      
//      for(let filter of getState().userEntityFilters.recentFilters) {
//          if(isEqual(filter, recentFilter)) 
//              return;
//      }
      dispatch(request());
      const recentFilter = userEntityFiltersFormatter.formatRecentFilter(metadata, title);

      return userEntityFiltersService.getRecentEntityFilters()
      .then(({data:recents})=>{
          const { entities } = recentFilter;
          for(let recent of recents) {
              const recentEntities = recent.entities;
              if(entities.length != recentEntities.length)
                  continue;
              
              let equal = true;
              for(let recentEntity of recentEntities) {
                  if(!find(entities, entity=>(entity.entity_type == recentEntity.entity_type && entity.entity_id == recentEntity.entity_id)))
                      equal = false;
              }
              // filter exists
              if(equal)
                  return dispatch(success);    
              
          }
          userEntityFiltersService.createFilter(recentFilter)
          .then(({data})=>{
              let requests = [],
                  id = data.id;
              
              for(let entity of entities){
                  requests.push(userEntityFiltersService.addToFilter(id, entity));
              }
              return Promise.all(requests)
              .then(()=>{
                  getRecentEntityFilters();
                  dispatch(success());
              });
          })
      })
    }
    function request() { return { type: userEntityFiltersConstants.UPDATE_USER_ENTITY_FILTER_REQUEST }};
    function success() { return { type: userEntityFiltersConstants.UPDATE_USER_ENTITY_FILTER_SUCCESS}};
    function failure(error) { return { type: userEntityFiltersConstants.UPDATE_USER_ENTITY_FILTER_FAILURE, error}};

  }


function getRecentEntityFilters(){
    return (dispatch, getState) => {
      dispatch(request());
      const currentUser = getState().user.user;
      return userEntityFiltersService.getRecentEntityFilters()
      .then( response => {
          const { filters, playlistFilters } = userEntityFiltersFormatter.formatUserEntityFilters(response, currentUser);
          dispatch(success(filters));
          return filters;
      })
      .catch( error => {
        dispatch(failure(error));
      })
    }
    function request() { return { type: userEntityFiltersConstants.GET_RECENT_ENTITY_FILTERS_REQUEST }};
    function success(filters) { return { type: userEntityFiltersConstants.GET_RECENT_ENTITY_FILTERS_SUCCESS, filters }};
    function failure(error) { return { type: userEntityFiltersConstants.GET_RECENT_ENTITY_FILTERS_FAILURE, error }};
  }

function clearRecentEntityFilters(){
    return (dispatch, getState) => {
      dispatch(request());
      return userEntityFiltersService.clearRecentEntityFilters()
      .then( response => {
          const filter = [];
          dispatch(success(filter));
          return filter;
      })
      .catch( error => {
        dispatch(failure(error));
      })
    }
    function request() { return { type: userEntityFiltersConstants.GET_RECENT_ENTITY_FILTERS_REQUEST }};
    function success(filters) { return { type: userEntityFiltersConstants.GET_RECENT_ENTITY_FILTERS_SUCCESS, filters }};
    function failure(error) { return { type: userEntityFiltersConstants.GET_RECENT_ENTITY_FILTERS_FAILURE, error }};
  }