import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { find, cloneDeep, get, capitalize, map, pick, isEqual } from 'lodash';
import {Helmet} from 'react-helmet';
import ReactTable from "react-table";
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { pdf as PDFRenderer }from '@react-pdf/renderer';
import parseISO from "date-fns/parseISO";

import CSV from '../../helpers/CSVExport';
import Spinner from '../../components/common/Spinner';
import Box from '../../components/common/Box';
import ModeSelect from '../../components/common/ModeSelect';
import SharePage from '../../components/pages/sharedPages/SharePage';
import MatchMediaWrapper from '../../helpers/MatchMediaWrapper';
import FilterButton from '../../components/widgets/spotifyMonetizationFilter/FilterButton';
import CatalogOverview from '../../components/pages/spotifyMonetization/Overview';
import CatalogPieChart from '../../components/pages/spotifyMonetization/PieChart';
import CatalogTimeseries from '../../components/pages/spotifyMonetization/Timeseries';
import MonthlyStats from '../../components/pages/spotifyMonetization/MonthlyStats';
import PDFDocument from '../../components/pages/spotifyMonetization/PDF';

import Datepicker from '../../components/common/Datepicker';
import {Widget, WithWidgetManager, HiddenElements} from '../../components/widgets/manager';
import cellHeaderHOC from '../../components/common/Datagrid/CellHeaderHOC';

import { spotifyMonetizationActions } from '../../data/actions/spotify_monetization';
import { spotifyMonetizationSettings } from '../../components/pages/spotifyMonetization/Settings';


const CellHeaderTable = cellHeaderHOC(ReactTable);
const pieSvg = require('!svg-inline-loader!../../../public/img/pie.svg');
const lineSvg = require('!svg-inline-loader!../../../public/img/line.svg');
const downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');

class SpotifyMonetizationReport extends React.Component {

	reportFirstDate = '2024-04-01';
	
	constructor(props){
		super(props);
		this.state = {
			dateStart: '',
			dateEnd: '',
			allowedDates: [],
			defaultSorted: [{id: 'end_month_streams', desc: true}],
			filtered: {},
			pageSize: 10,
			page: 0,
			chartMode: 'line',
			sorted: [{id: 'end_month_streams', desc: true}],
		}
		this.dateChange = this.dateChange.bind(this);
		this.setChartMode = this.setChartMode.bind(this);
	}    
	
	componentDidMount(){
		this.props.dispatch(spotifyMonetizationActions.getSpotifyMonetizationDates(this.props.platform));// .then(allowedDates=>{
			const { dates } = this.props.match.params;
			let { dateStart, dateEnd } = this.state;
			if(dates) {
				[ dateStart, dateEnd ] = dates.split('_');
			}
			this.setState({
				dateStart, 
				dateEnd,
			}, ()=>{
				this.dispatchLoadData(true);	
			})
		//})
	}
	
	exportToCsv = () => {
		const { platform } = this.props;
		const metrics = this.props.spotifyMonetization.totals[platform];
		const filename = CSV.CSVHeader('spotify-monetization', this.state.dateStart);
		return CSV.CSVExport(metrics, {filename}, 'spotify_monetization_totals');
	}

	exportToCsvTracks = () => {
		const { platform } = this.props;
		const { data } = this.props.spotifyMonetization.data[platform];
		const filename = CSV.CSVHeader('spotify-monetization-tracks', this.state.dateStart);
		return CSV.CSVExport(data, {filename}, 'spotify_monetization');
	}
	
	exportToXls = () => {
		const { platform } = this.props;
		const data = [
			{'spotify_monetization_totals': get(this.props, `spotifyMonetization.totals.${platform}`)},
			{'spotify_monetization': get(this.props, `spotifyMonetization.data.${platform}.data`)}
		];
		const filename = `spotify_monetization_${this.state.dateStart}_${this.state.dateEnd}`;
		CSV.CSVBulkExport(data, filename);
	}	

	getPdfData() {
		const { platform } = this.props;
		const reportTitle = `Monetization: ${this.state.dateStart} - ${this.state.dateEnd}`,
			{ spotifyMonetization } = this.props;
			
		const imageIDs = [
			  'spotify_monetization_metrics'
		];
		let images = {};
		for (let imageID of imageIDs){
			let image = document.getElementById(imageID);
			if(image)
				images[imageID] = image.toDataURL("image/jpeg,1.0"); 
		}
		
		const tables = {
			data: get(spotifyMonetization, `data.${platform}.data`)
		};
		return { reportTitle, images, tables };
	}
	
	exportToPdf = () => {
		const data = PDFRenderer(<PDFDocument data={this.getPdfData()} />);
		data.toBlob()
		.then(blob=>{
			const fileURL = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = fileURL;
			link.download = `monethzation_${this.state.dateStart}_${this.state.dateEnd}.pdf`;
			link.click();
		});
	}
	
	dispatchLoadData(totals = false){
		const { page, pageSize, sorted } = this.state;
		const {platform } = this.props;
		const share = this.props.sharedMode ? '/share' : '';
		let filtered = cloneDeep(this.state.filtered);
		const { dateStart, dateEnd } = this.state;
		if(!dateStart || !dateEnd)
			return;
		
		const dates = `${dateStart}_${dateEnd}`;
		filtered.dateStart = dateStart;
		filtered.dateEnd = dateEnd;

		let params = {};
		params.page = page;
		params.pageSize = pageSize;
		params.filtered = filtered;
		params.sorted = sorted;
		params.platform = platform;

		this.props.dispatch(spotifyMonetizationActions.getSpotifyMonetization(params));
		if(totals) {
			this.props.dispatch(spotifyMonetizationActions.getSpotifyMonetizationTotals(params));
			this.props.dispatch(spotifyMonetizationActions.getSpotifyMonetizationTimeseries(params));
		}
		this.props.history.push(`${share}/reporting/monetization/${platform}/${dates}`)
	}
	
	setChartMode(chartMode){
		this.setState({
			chartMode
		})
	}


	loadData = (params) => {
	  params = pick(params, ['page', 'pageSize', 'sorted']);
	  const currentState = pick(this.state, ['page', 'pageSize', 'sorted']);
	  if(isEqual(params, currentState))
		  return;

	  this.setState({
		  page: params.page,
		  pageSize: params.pageSize,
		  sorted: params.sorted,
	  }, ()=>{
		  this.dispatchLoadData()
	  });
    }

	
	dateChange(date, field){
		const { dispatch } = this.props; 
		date = moment(date).format('YYYY-MM-DD');
		let { dateEnd } = this.state;
		if(field == 'dateStart')
			dateEnd = moment(date).add(1, 'month').format('YYYY-MM-DD');
			
		this.setState({
		  dateEnd,		
		  [field]: date
		}, ()=>{
			this.dispatchLoadData(true);
		});
	}
	
	getMinEndDate = (mode) => {
		if(mode == 'start')
			return this.reportFirstDate;
		else {
			let { dateStart = this.reportFirstDate } = this.state;
			return moment(dateStart).add(1, 'month').format('YYYY-MM-DD');
		}
	}
	
	onApply = (filtered) => {
		this.setState({filtered}, ()=>{
			this.dispatchLoadData(true)
		})
	}

	renderToolbar(){
		let toolbar = [];
	
		const options = [
			{icon: pieSvg, value: 'pie', label: "Donut Chart"},
			{icon: lineSvg, value: 'line', label: "Line Chart"},
		];
	
		toolbar.push(<div className="ibox-action-wrapper" key="ibox">
			<div className="ibox-icon-holder mode-select catalog-select">
				<ModeSelect options={options} selected={this.state.chartMode} onChange={this.setChartMode} isSearchable={ false } />
			</div>
		</div>)
	
		return toolbar;
	}
	
	renderExportToCsv() {
		return (
			<a key="download" title="Export CSV" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
		)
	}

	renderExportToCsvTracks() {
		return (
			<a key="download" title="Export CSV" onClick={this.exportToCsvTracks} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
		)
	}
	
	render() {
		const { data, loading, totals, loadingTotals, timeseries, loadingTimeseries, dates } = this.props.spotifyMonetization;
		
		const { widgetProps, sharedMode, platform } = this.props;
		const { pageSize, page, sorted, chartMode, dateStart, dateEnd } = this.state;
		const tableData = get(data[platform], 'data', []);
		const total = get(data[platform], 'total', -1);
		const pages = Math.ceil(total / pageSize)
		const allowedDates = dates && dates[platform] ? dates[platform] : null;  //get(this.props, 'spotifyMonetization.dates', null);
		if(!allowedDates)
			return <Spinner enabled={true} />

		const datesBlock = <div className='filter-date-holder'>
		<div className="new-release-datepicker">
			<span className="calendar-filter-label">Start Month</span>
			{/* endDate={previousFriday()} */}
			<Datepicker showMonthYearPicker={true} date={this.state.dateStart} includeDates={allowedDates} dateChange={(date)=>this.dateChange(date, 'dateStart')} container="#new-releases-header-start" />
		</div>
		<div className="new-release-datepicker">
			<span className="calendar-filter-label">End Month</span>
			{/* endDate={previousFriday()} */}
			<Datepicker showMonthYearPicker={true} date={this.state.dateEnd} includeDates={allowedDates} dateChange={(date)=>this.dateChange(date, 'dateEnd')}  container="#new-releases-header-end" />
		</div>
	</div>

		return (<div className="homepage">
				<div className="ibox main-holder">
  			<div className="section-header">
					<h2 className="ibox-title__title title-with-padding">
						<div className="title-text">
							{capitalize(platform)} Monetization
							{/*<div className="title-tooltip release">
								<span data-tip data-for="new-release-tip" className="notificationReportInfoIcon toolbar-title-tooltip-icon"> 
									<i className="fas fa-question-circle" /> 
								</span>
								<ReactTooltip effect="solid" multiline={true} place="bottom" className="top-sections-tooltip" id="new-release-tip">
									todo
								</ReactTooltip>
							</div>*/}
						</div>
						<div>
							{!sharedMode && <SharePage module="reporting" entity="monetization" id={platform} params={`${dateStart}_${dateEnd}`} exportToCsv={() => this.exportToXls()} exportToPdf={() => this.exportToPdf()} />}              
							{sharedMode && <a key="download" onClick={() => this.exportToXls()} title="Export CSV" className="download-link download-link-shared-heartbeats" dangerouslySetInnerHTML={{__html: downloadSvg}} />}
						</div>
					</h2>
  			</div>  
  			<div className="new-releases-header spotify" id="new-releases-header-start">
					<div className='filter-with-date-holder'>
						<FilterButton onApply={this.onApply} filtered={this.state.filtered} datesBlock={datesBlock} />
					</div>
				</div>
				{(totals[platform] && !loadingTotals) ? <div className="tiles-overview-holder">
						<CatalogOverview stats={totals[platform]} />
					</div> : <div>{loadingTotals && <Spinner enabled={true} />} </div> }
				{(totals[platform] && !loadingTotals) ? <div className="widget-with-border-bottom">
					<Box title="Streams" toolbar={this.renderToolbar()}  exportToCsv={this.renderExportToCsv()} >
						{chartMode == 'pie' && <CatalogPieChart stats={totals[platform]} />}
						{chartMode == 'line' && <CatalogTimeseries stats={timeseries[platform]} />}
					</Box>
				</div> : <div>{loadingTotals && <Spinner enabled={true} />} </div> }
				<div className="widget-no-title">
					<Box title="" exportToCsv={this.renderExportToCsvTracks()}>
						<CellHeaderTable
							defaultSorted={this.state.defaultSorted}
							defaultSortDesc={true}
							defaultPageSize={pageSize}
							//resizable={false}
							loading={loading}
							className="new-releases-table"
							noDataText='No results found. Please pick another month.'
							columns={spotifyMonetizationSettings.settingsForTable()}
							data={tableData}
							manual
							onFetchData={this.loadData}
							//page={page}
							pages={pages}
							//sorted={sorted}
							collapseOnDataChange={false}
							SubComponent={row=><MonthlyStats data={row.original.monthly_details} isrc={row.original.isrc} />}
						/>
					</Box>
				</div>
			</div>

			<Helmet>
					<title>Monetization - Reports</title>
			</Helmet>
		</div>);
	}
}

function mapStateToProps(state) {
	return {
		spotifyMonetization: state.spotifyMonetization
	} 
}
export default connect(mapStateToProps)(withRouter(WithWidgetManager(SpotifyMonetizationReport, 'spotify_report')));